import * as React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";

interface Props {
  color: string;
  hover?: string;
  text: string;
  link: string;
}

interface State {}

class Button extends React.Component<Props, State> {
  public render() {
    return (
      <Link
        to={this.props.link}
        className={
          "flex-1 text-white text-center font-bold py-2 px-4 " +
          ` hover:${this.props.hover}` +
          ` ${this.props.color}`
        }
      >
        <OutboundLink href={this.props.link}>{this.props.text}</OutboundLink>
      </Link>
    );
  }
}

export default Button;
