import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import Button from "./ui/button";

interface IProduct {
  node: {
    frontmatter: {
      title: string;
      image: string;
      summary: string;
    };
    fields: {
      path: string;
    };
  };
}

interface Props {
  products: IProduct[];
}

interface State {}

const SummaryBox = styled.p`
  overflow: hidden;
  overflow-y: auto;
`;

class ProductsList extends React.Component<Props, State> {
  public render() {
    return (
      <div className="flex flex-wrap justify-around">
        {this.props.products.map((product, i) => (
          <div
            key={i}
            className={
              "w-full xl:w-5/12 my-5 bg-gray-100 overflow-hidden px-5 py-5 rounded shadow-md hover:shadow-lg" +
              (i % 2 == 0 ? " " : "")
            }
          >
            <div className="md:flex h-full">
              <div className="md:flex-shrink-0 flex items-center">
                <img
                  className="w-48 mx-auto"
                  src={product.node.frontmatter.image}
                  alt={product.node.frontmatter.title}
                />
              </div>
              <div className="mt-4 md:mt-0 md:ml-6 flex flex-col w-full">
                {/* <div className="uppercase tracking-wide text-sm text-pink-600 font-bold">
                  {product.relationships.stage.name}
                </div> */}
                <Link
                  to={product.node.fields.path}
                  className="block mt-1 text-lg md:text-xl lg:text-2xl leading-tight font-semibold text-gray-900 hover:underline"
                >
                  {product.node.frontmatter.title}
                </Link>
                <SummaryBox className="mt-2 text-gray-800 ">
                  {product.node.frontmatter.summary}
                </SummaryBox>
                <div className="flex mt-5 md:mt-auto">
                  <Button
                    text="Learn more"
                    link={product.node.fields.path}
                    color="bg-blue-700"
                    hover="bg-blue-900"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default ProductsList;
