import * as React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

interface Props {
  trail: { page: string; path: string }[];
}

interface State {}

class Breadcrumb extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const trail = this.props.trail;
    return (
      <div className="text-gray-400 text-sm font-bold flex space-x-4 flex-no-wrap overflow-hidden overflow-x-auto">
        <span className="flex-none">
          <Link className="hover:text-teal-400 pr-4 border-r-2" to="/">
            <FontAwesomeIcon style={{ maxWidth: "17px" }} icon={faHome} />
          </Link>
        </span>
        {trail.map((l, i) => (
          <span key={i} className="flex-none">
            <Link
              className={
                "hover:text-teal-400 " + (trail[i + 1] ? "pr-4 border-r-2" : "")
              }
              to={l.path}
            >
              {l.page}
            </Link>
          </span>
        ))}
      </div>
    );
  }
}

export default Breadcrumb;
