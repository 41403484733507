import * as React from "react";
import { graphql } from "gatsby";

import BasicLayout from "../layouts/BasicLayout";
import Section from "../components/ui/section";
import Container from "../components/ui/container";
import PageHeader from "../components/ui/pageHeader";
import ProductsList from "../components/productsList";

class Series extends React.Component {
  render() {
    const { data } = this.props;
    const page = {
      title: "Products",
      image: "/images/Banner_1_0.jpg",
    };
    const subject = data.subject;
    const products = data.products.edges;
    return (
      <div>
        <BasicLayout pageTitle={page.title}>
          <PageHeader
            title={page.title}
            image={page.image}
            breadcrumb={[{ page: page.title, path: this.props.uri }]}
          />
          <div>
            <Section background="bg-white">
              <Container padding>
                <div className="text-center mx-auto">
                  <img
                    className="w-10/12 md:w-2/3 lg:w-2/5 mx-auto"
                    src={subject.frontmatter.image}
                    alt={subject.frontmatter.title}
                  />
                  {/* <br />
                  {series.relationships.field_product_preview && (
                    <PdfPreview
                      file={`https://admin.ugaritpublishing.com${series.relationships.field_product_preview?.uri?.url}`}
                      buttonText="About the Series"
                    />
                  )} */}
                </div>
              </Container>
            </Section>
            {products && (
              <Section>
                <Container padding>
                  <ProductsList products={products} />
                </Container>
              </Section>
            )}
          </div>
        </BasicLayout>
      </div>
    );
  }
}

export default Series;

export const SubjectQuery = graphql`
  query SubjectQuery($alias: String!, $title: String!) {
    subject: mdx(fields: { path: { eq: $alias } }) {
      frontmatter {
        image
      }
    }
    products: allMdx(
      filter: {
        frontmatter: { layout: { eq: "product" }, subject: { eq: $title } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            image
            summary
          }
          fields {
            path
          }
        }
      }
    }
  }
`;
